import About from "./components/About";
import Contacts from "./components/Contacts";

import { BrowserRouter, Link, Route, Routes } from "react-router-dom";

import "./crud.scss";
import Cv from "./components/Cv";
import Projects from "./components/Projects";
import Navigation from "./components/Navigation";
import { useState } from "react";
import Game from "./components/Game";
import logo from "../src/img/lm.jpg";

function App() {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };
  return (
    <div className="App">
      <BrowserRouter>
        <header className="App-header">
          {" "}
          {/* <div className="logo-column">
            <img className="logo" src={logo} alt={logo}></img>
          </div> */}
          {/* <h2>Laura Meižikienė</h2> */}
          <div className="hamburger" onClick={toggleHamburger}>
            <Navigation />
          </div>
          <div
            className={
              !hamburgerOpen ? "navigation-bar inactive" : "navigation-bar show"
            }
          >
            <Link className="nav-link" to="/home" onClick={toggleHamburger}>
              Home
            </Link>

            <Link
              className="nav-link "
              to="/projects"
              onClick={toggleHamburger}
            >
              Projects
            </Link>

            <Link className="nav-link" to="/game" onClick={toggleHamburger}>
              Games
            </Link>
            <Link className="nav-link" to="/cv" onClick={toggleHamburger}>
              CV
            </Link>
            <Link className="nav-link" to="/contacts" onClick={toggleHamburger}>
              Contact me
            </Link>
          </div>
        </header>
        <div className="logo-column">
          <img className="logo" src={logo} alt={logo}></img>
        </div>
        <Routes>
          <Route path="/" element={<About></About>}></Route>
          <Route path="/home" element={<About></About>}></Route>
          <Route path="/projects" element={<Projects></Projects>}></Route>
          <Route path="/game" element={<Game></Game>}></Route>
          <Route path="/cv" element={<Cv></Cv>}></Route>{" "}
          <Route path="/contacts" element={<Contacts></Contacts>}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
