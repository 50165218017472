// import about from "../img/self2.jpg";
import Projects from "./Projects";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
export default function About() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="about-title typewriterHello">
        <h1>Hello</h1>
      </div>
      <div data-aos="fade-right" className="about">
        {/* <img src={about} alt={about} /> */}

        <p>
          Welcome to my portfolio. My name is Laura. I'm a beginner front-end
          programmer. I have completed a JavaScript course this summer and now I
          would like to apply my knowledge in the real projects.{" "}
        </p>
      </div>
    </>
  );
}
