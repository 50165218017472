import formEx from "../img/form.png";

export default function Projects() {
  return (
    <>
      <h1 className="project-title typewriter"> My studies projects</h1>

      <div className="projects">
        <div className="projectImg">
          <a href="https://laurameiz.github.io/Warehouse/" target="_blank">
            {/* <img className="projectImg" src={formEx} /> */}
            <h4>WareHouse</h4>
          </a>
        </div>
        <div className="recepiesImg">
          <a href="https://laurameiz.github.io/RecepiesApp/" target="_blank">
            <h4>Recepies-Search </h4>
          </a>
        </div>
        <div className="countriesImg">
          {" "}
          <a href="https://laurameiz.github.io/Countries/" target="_blank">
            <h4>Countries</h4>
          </a>
        </div>
        <div className="filmsImg">
          {" "}
          <a href="https://laurameiz.github.io/Films-search/" target="_blank">
            <h4>Films</h4>
          </a>
        </div>
        <div className="quizImg">
          {" "}
          <a href="https://laurameiz.github.io/myquiz/" target="_blank">
            <h4>Quiz</h4>
          </a>
        </div>
      </div>
    </>
  );
}
