export default function Game() {
  return (
    <>
      <h1 className="game-title">Games</h1>
      <div className="game-container">
        <div className="BallImg">
          {" "}
          <a href="https://laurameiz.github.io/Game001/" target="_blank">
            <h4>Ball's game</h4>
          </a>
        </div>
        <div className=" ticImg">
          {" "}
          <a href="https://laurameiz.github.io/tiktactoe/" target="_blank">
            <h4>Tic tac toe (with frend)</h4>
          </a>
        </div>
        <div className=" ticCompImg">
          {" "}
          <a href=" https://laurameiz.github.io/nextiktactoe/" target="_blank">
            <h4> Tic tac toe (with computer)</h4>
          </a>
        </div>
        <div className=" gameImg">
          {" "}
          <a
            href="https://laurameiz.github.io/scissor-rock-paper/"
            target="_blank"
          >
            <h4>Scissor Paper Rock</h4>
          </a>
        </div>
      </div>
    </>
  );
}
