import React from "react";
import "../contacts.scss";
import { MdOutlineMail } from "react-icons/md";
import { RiMessengerLine } from "react-icons/ri";
import { useRef } from "react";
import emailjs from "emailjs-com";
// import Typed from "react-typed";

export default function Contacts() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_57561jo",
        "template_3jzvty2",
        form.current,
        "sgEloiYxy_ovBv-Kr"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <>
      <h2>Contact Me</h2>
      <form ref={form} onSubmit={sendEmail}>
        <input type="text" name="name" placeholder="Your full name" required />
        <input type="email" name="email" placeholder="Your email" required />
        <textarea
          name="message"
          rows="7"
          placeholder="Your message"
          required
        ></textarea>
        <div className="btn">
          <button className="btn-send" type="submit">
            Send Message
          </button>
        </div>
      </form>
      <div className="contact-container">
        <div className="contact-column">
          <div className="contact-options">
            <article className="contacts">
              <h4>
                {" "}
                <MdOutlineMail /> Email
              </h4>
              <h5>laurameizikiene@gmail.com</h5>
              <a href="mailto:laurameizikiene@gmail.com" target="blank">
                Send a message
              </a>
            </article>
            <article className="contacts">
              <h4>
                {" "}
                <RiMessengerLine /> Messenger
              </h4>
              <h5>Laura Prapiestyte</h5>
              <a href="http://m.me/laura.prapiestyte" target="blank">
                Send a message
              </a>
            </article>
          </div>
        </div>
      </div>
    </>
  );
}
