import cv1 from "../img/1.png";
import cv2 from "../img/2.png";
// import cv3 from "../img/Screenshot 2022-10-24 103055.png";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
export default function Cv() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div data-aos="fade-up" className="cv">
        <h4>Curriculum Vitae (Cv) </h4>
        <div className="cv-img">
          {/* <img src={cv3} alt={cv3} /> */}
          <img src={cv1} alt={cv1} />
          <img src={cv2} alt={cv2} />
        </div>
      </div>
    </>
  );
}
